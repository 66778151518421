<template>
  <page-header-wrapper @back="back">
    <template v-slot:breadcrumb>
      {{ formTitle }}
    </template>
    <template v-slot:title>
      {{ formTitle }}
    </template>
    <template v-slot:content> </template>
    <template v-slot:extraContent> </template>
    <a-card :bordered="false">
      <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="公告标题" prop="noticeTitle">
          <a-input v-model="form.noticeTitle" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="公告类型" prop="noticeType">
          <a-select placeholder="请选择" @change="typeChange" v-model="form.noticeType">
            <a-select-option v-for="(d, index) in typeOptions" :key="index" :value="d.dictValue">{{
              d.dictLabel
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="status">
          <a-radio-group v-model="form.status" button-style="solid">
            <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{
              d.dictLabel
            }}</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="封面图片" v-if="form.noticeType === '3' || form.noticeType === '4'" prop="mileageImage">
          <file-upload
            v-model="form.coverImage"
            folder="official/article"
            type="image"
            uploadImgUri="/common/uploadPublicReadFile"
            :limitPixels="fixedNumber"
            @rateErrorBack="rateErrorBack"
          ></file-upload>
        </a-form-model-item>
        <a-form-model-item label="资讯时间" v-if="form.noticeType === '3'" prop="articleTime">
          <a-date-picker
            style="width: 100%"
            v-model="form.articleTime"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="资讯来源" v-if="form.noticeType === '3'" prop="articleSource">
          <a-input v-model="form.articleSource" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="资讯排序" v-if="form.noticeType === '3'" prop="sort">
          <a-input v-model="form.sort" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="案例排序" v-if="form.noticeType === '4'" prop="sort">
          <a-input v-model="form.sort" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="内容" prop="noticeContent"> </a-form-model-item>
        <div id="vditor" name="description"></div>
        <avatar-modal ref="modal" @ok="setavatar" />
        <footer-tool-bar>
          <a-space>
            <a-button type="primary" @click="submitForm"> 发布 </a-button>
            <a-button type="dashed" @click="back"> 取消 </a-button>
          </a-space>
        </footer-tool-bar>
      </a-form-model>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getNotice, addNotice, updateNotice } from '@/api/system/notice'
import Vditor from 'vditor'
import 'vditor/dist/index.css'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { uuid } from '@/utils/util.js'
import AvatarModal from './AvatarModal'

export default {
  name: 'CreateForm',
  components: { AvatarModal },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: false,
      total: 0,
      id: undefined,
      formTitle: '',
      contentEditor: '',
      // 状态数据字典
      statusOptions: [],
      typeOptions: [],
      // 表单参数
      form: {
        noticeId: undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeContent: '',
        articleSource: '',
        articleTime: '',
        coverImage: '',
        status: '0'
      },
      rules: {
        noticeTitle: [{ required: true, message: '公告标题不能为空', trigger: 'blur' }],
        noticeType: [{ required: true, message: '公告类型不能为空', trigger: 'change' }]
      },
      uploadImgUrl: process.env.VUE_APP_BASE_API + 'common/uploadPublicReadFile',
      rate: '420:248',
      option: {
        img: '',
        size: 1,
        full: false,
        outputType: 'jpeg'
      },
      fixedNumber: [420, 248]
    }
  },
  filters: {},
  created() {
    this.getDicts('sys_notice_status').then((response) => {
      this.statusOptions = response.data
    })
    this.getDicts('sys_notice_type').then((response) => {
      this.typeOptions = response.data
    })
    this.id = this.$route.params.id
    this.formTitle = this.$route.params.formTitle
    if (this.id) {
      this.handleUpdate(this.id)
    } else {
      this.handleAdd()
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.contentEditor = new Vditor('vditor', {
      height: 360,
      toolbarConfig: {
        pin: true
      },
      cache: {
        enable: false
      },
      after: () => {
        this.contentEditor.setValue(this.form.noticeContent)
      },
      upload: {
        url: this.uploadImgUrl,
        headers: {
          Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN)
        },
        accept: 'image/*',
        multiple: false,
        extraData: {
          fileName: uuid(),
          type: 'aliyunoss',
          folder: 'official/article'
        },
        fieldName: 'file',
        success: (el, res) => {
          console.log('success', res)
          const obj = JSON.parse(res)
          console.log('getCurrentMode', this.contentEditor.getCurrentMode())
          if (this.contentEditor.getCurrentMode() === 'wysiwyg') {
            this.contentEditor.updateValue(`<img src="${obj.url}" alt="">`)
          } else {
            this.contentEditor.updateValue(this.contentEditor.html2md(`<img src="${obj.url}" alt="">`))
          }
        },
        linkToImgFormat: (res) => {
          console.log('linkToImgFormat', res)
        },
        linkToImgCallback: (res) => {
          console.log('linkToImgCallback', res)
        }
      }
    })
  },
  methods: {
    cutDown(event) {
      console.log(event)
    },
    typeChange(e) {
      this.form.sort = parseInt((this.form.noticeType === '3' ? this.articelSort : this.caseSort)) + 1
      this.fixedNumber = this.form.noticeType === '3' ? [420, 248] : [635, 390]
    },
    // 表单重置
    reset() {
      this.form = {
        noticeId: undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeContent: '',
        status: '0'
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.formTitle = '添加公告'
      this.articelSort = this.$route.params.articelSort
      this.caseSort = this.$route.params.caseSort
      this.lastCoverImage = null
    },
    rateErrorBack(file) {
      // 比例不适合回调， 此次进入裁剪
      console.log('rateErrorBack')
      var reader = new FileReader()
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.$refs.modal.edit(data)
      }
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file)
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset()
      const noticeId = id
      getNotice(noticeId).then((response) => {
        this.form = response.data
        this.formTitle = '修改公告'
        this.coverImage = this.$route.params.coverImage
        console.log('this.coverImage', this.coverImage)
        this.coverImage ? (this.lastCoverImage = this.form.coverImage) : (this.lastCoverImage = null)
        this.form.coverImage = this.coverImage
        this.contentEditor.setValue(response.data.noticeContent)
      })
    },
    getContentImgList(noticeContent) {
      const reg = /!\[\]\((.+?)\)/g
      const arr = noticeContent.match(reg) || []
      const imgList = []
      for (const item of arr) {
        const fileName = item
          .replace('![](https://ywkj-cloud.oss-cn-shanghai.aliyuncs.com/picture/', '')
          .replace(')', '')
        imgList.push(fileName)
      }
      console.log('noticeContent_arr', imgList)
      return imgList
    },
    /** 提交按钮 */
    submitForm: function () {
      const form = JSON.parse(JSON.stringify(this.form))
      form.noticeContent = this.contentEditor.getValue()
      form.listImages = this.getContentImgList(form.noticeContent)
      if (form.noticeType !== '3' && form.noticeType !== '4' && !this.id) {
        delete form.sort
      }
      if (
        (form.coverImage.indexOf('https://') > -1 || form.coverImage.indexOf('http://') > -1) &&
        this.lastCoverImage
      ) {
        form.coverImage = this.lastCoverImage
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (form.noticeId !== undefined) {
            updateNotice(form).then((response) => {
              this.$message.success('修改成功', 3)
              this.back()
            })
          } else {
            addNotice(form).then((response) => {
              this.$message.success('新增成功', 3)
              this.back()
            })
          }
        } else {
          return false
        }
      })
    },
    back() {
      this.$router.push('/system/notice?noticeType=' + this.form.noticeType)
    },
    setavatar(url, fileName) {
      console.log('setavatar', arguments)
      this.lastCoverImage = fileName
      this.$set(this.form, 'coverImage', url)
    }
  }
}
</script>
<style lang="less" scoped>
#vditor {
  margin: 0 5% 5% 5%;
}
</style>
