var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    {
      on: { back: _vm.back },
      scopedSlots: _vm._u([
        {
          key: "breadcrumb",
          fn: function () {
            return [_vm._v(" " + _vm._s(_vm.formTitle) + " ")]
          },
          proxy: true,
        },
        {
          key: "title",
          fn: function () {
            return [_vm._v(" " + _vm._s(_vm.formTitle) + " ")]
          },
          proxy: true,
        },
        {
          key: "content",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
        {
          key: "extraContent",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "公告标题", prop: "noticeTitle" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.noticeTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "noticeTitle", $$v)
                      },
                      expression: "form.noticeTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "公告类型", prop: "noticeType" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.typeChange },
                      model: {
                        value: _vm.form.noticeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "noticeType", $$v)
                        },
                        expression: "form.noticeType",
                      },
                    },
                    _vm._l(_vm.typeOptions, function (d, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: d.dictValue } },
                        [_vm._v(_vm._s(d.dictLabel))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (d, index) {
                      return _c(
                        "a-radio-button",
                        { key: index, attrs: { value: d.dictValue } },
                        [_vm._v(_vm._s(d.dictLabel))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.noticeType === "3" || _vm.form.noticeType === "4"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "封面图片", prop: "mileageImage" } },
                    [
                      _c("file-upload", {
                        attrs: {
                          folder: "official/article",
                          type: "image",
                          uploadImgUri: "/common/uploadPublicReadFile",
                          limitPixels: _vm.fixedNumber,
                        },
                        on: { rateErrorBack: _vm.rateErrorBack },
                        model: {
                          value: _vm.form.coverImage,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "coverImage", $$v)
                          },
                          expression: "form.coverImage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.noticeType === "3"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "资讯时间", prop: "articleTime" } },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "YYYY-MM-DD",
                          format: "YYYY-MM-DD",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.form.articleTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "articleTime", $$v)
                          },
                          expression: "form.articleTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.noticeType === "3"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "资讯来源", prop: "articleSource" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.articleSource,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "articleSource", $$v)
                          },
                          expression: "form.articleSource",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.noticeType === "3"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "资讯排序", prop: "sort" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.noticeType === "4"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "案例排序", prop: "sort" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("a-form-model-item", {
                attrs: { label: "内容", prop: "noticeContent" },
              }),
              _c("div", { attrs: { id: "vditor", name: "description" } }),
              _c("avatar-modal", { ref: "modal", on: { ok: _vm.setavatar } }),
              _c(
                "footer-tool-bar",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 发布 ")]
                      ),
                      _c(
                        "a-button",
                        { attrs: { type: "dashed" }, on: { click: _vm.back } },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }